.rotate {
  display: inline-block;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.hidden {
  visibility: hidden;
}

.full-width-input {
  display: flex;
  color: lightcyan;
  height: 4em;
  width: 100%;
}
